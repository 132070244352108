<template>
  <div id="plan">
    <div class="head-container">
      <div
        class="item"
        v-for="(item, index) in typeList"
        :key="index"
        @click="goVideo(item.id)"
      >
        <van-image width="80" height="80" :src="item.url" />
        <div class="name">{{ item.name }}</div>
      </div>
    </div>
    <div class="content">
      <div class="title">
        <div class="tip">练习</div>
        <div @click="renderData()">
          <van-icon name="replay" style="margin-right:10px;" /><span
            >换一批</span
          >
        </div>
      </div>
      <div class="video-content">
        <div
          class="video-item"
          v-for="(item, index) in videoList"
          :key="index"
          @click="goDetail(item)"
        >
          <van-image
            width="100%"
            height="110px"
            :src="item.coverUrl"
            fit="contain"
          />

          <div class="info">
            <div class="info-title">{{ item.videoName }}</div>
            <div><van-icon name="play-circle-o" /></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../../common/js/api';
export default {
  data() {
    return {
      typeList: [
        {
          name: '上肢',
          id: '上肢力量',
          url: require('../../common/img/1.png'),
        },
        {
          name: '下肢',
          id: '下肢力量',
          url: require('../../common/img/2.png'),
        },
        {
          name: '灵敏',
          id: '灵敏性',
          url: require('../../common/img/3.png'),
        },
        {
          name: '柔韧',
          id: '柔韧性',
          url: require('../../common/img/4.png'),
        },
        {
          name: '协调',
          id: '协调性',
          url: require('../../common/img/5.png'),
        },
        {
          name: '平衡',
          id: '平衡力',
          url: require('../../common/img/6.png'),
        },
      ],
      videoList: [],
    };
  },

  components: {},

  computed: {},
  created() {
    this.renderData();
  },
  mounted() {},

  methods: {
    goVideo(id) {
      this.$router.push({
        path: '/video',
        query: {
          id: id,
        },
      });
    },
    renderData() {
      this.$post(api.videoService).then((res) => {
        let data = res.data;
        console.log(data);
        this.videoList = data;
      });
    },

    goDetail(item) {
      window.sessionStorage.setItem('videoInfo', JSON.stringify(item));
      this.$router.push('/videoDetail');
    },
  },
};
</script>
<style lang="scss" scoped>
#plan {
  .head-container {
    height: 35%;
    background: #fff;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    .item {
      width: 33%;
      text-align: center;
    }
    .name {
      font-weight: 500;
      color: #333333;
      font-family: PingFang SC;
    }
  }
  .content {
    margin-top: 3px;
    height: calc(100% - 35% - 3px);
    background: #fff;
    padding: 0 20px;
    overflow: auto;
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 40px;
      color: #333333;
      font-weight: 700;
      font-size: 15px;
      vertical-align: middle;
      .tip {
        position: relative;
        padding-left: 10px;
        &::before {
          position: absolute;
          content: '';
          left: 0;
          top: 0;
          width: 4px;
          height: 100%;
          background: #1989fa;
        }
      }
    }
    .video-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .video-item {
        width: 48%;
        height: 162px;
        margin-bottom: 10px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
        border-radius: 5px;
        position: relative;
        .time {
          position: absolute;
          left: 12px;
          top: 90px;
          font-size: 10px;
          color: #ffffff;
        }
        .info {
          padding: 5px 12px;
          .info-title {
            font-size: 14px;
            font-weight: 500;
            color: #333333;
            text-overflow: ellipsis;
            /*让截断的文字显示为点点。还有一个值是clip意截断不显示点点*/
            white-space: nowrap;
            /*让文字不换行*/
            overflow: hidden;
            /*超出要隐藏*/
          }
        }
      }
    }
  }
}
</style>
